// Core

@import "core";

// Fonts

@import url('https://fonts.googleapis.com/css?family=Heebo:400,700&display=swap');

// Widgets

@import "widgets";

// Components

@import "components";

// Layout Components

@import "layout-components";

// Layout Blueprints

@import "layout-blueprints";


.fade-enter {
  opacity: 0.01;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}
.codesCard {
  padding: 25px;
  border: 1px solid #c4c4c4;
  margin-top: 40px;
}
.myProfileCard {
  padding: 25px;
  border: 1px solid #c4c4c4;
  margin: 25px
}

.overflowForTable{
  // border-radius: 20px;
  display: block;
  position: relative;
  max-height:  calc(100vh - 300px);
  min-height:  350px;
  overflow: auto;
}

.fixedHeaderTable {
  position: sticky;
    top: 0px;
    margin: 0;
    z-index:9;
    background: #fff;
    
}

.rbc-time-content {
  display: none;
  }

.rbc-time-view {
   flex: inherit;
}

.rbc-time-header-gutter {
  display: none;
}

.rbc-event {
  width: auto !important;
}

.rbc-calendar *, .rbc-calendar *:before, .rbc-calendar *:after{
  box-sizing:border-box;
}

.rbc-today{
  background-color:#f8f9ff ;
}

.color-applied{
  background-color: #26C6F9 !important;
}

.color-completed{
  background-color:#26C6F9 !important;
}

.color-pending{
  background-color:#666cff !important;
}

.color-rejected{
  background-color:#ff4d4d !important;
}

.color-cancelled{
  background-color:#fdb528 !important;
}

.color-approved{
  background-color: #72E112 !important;

}
.color-skipped{
  background-color: #72E112 !important;

}

.status-label{
  border-radius: 25px;
  padding: 5px 0px;
  width: 130px !important;
  display: block;
  text-align: center;
}


.header {
  background: #f4f5fd;
  vertical-align: middle
}
.header th {
  // text-transform: uppercase !important;
  background: #f4f5fd;
  vertical-align: middle
}
.fixedProfile {
  width: 140px;
  height: 140px;
  font-size: 40px;
  color: white;
}
.fixedTableProfile {
  width: 60px;
  height: 60px;
  font-size: 20px;
  color: white;
}

.vertical-line {
  position: absolute;
  height: 100%;
  width: 1px;
  background-color: #c4c4c4;
}
 
.vertical-line.left {
  left: calc(33.33% - 1px);
}
 
.vertical-line.right {
  left: calc(66.666% - 1px);
}
.rotated {
  transform: rotate(180deg);
}
.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border-left-color: rgb(243, 174, 47);
  animation: spin 1s linear infinite;
  margin: auto;
}
 
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.td-border-0 {
  td {
  border:0 !important
  }
}

